/* index.css */
@import "~bootstrap/dist/css/bootstrap.min.css";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

.col-md-12 {
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  text-decoration: none;
}

.form-control {
  height: 60px;
  margin-bottom: 25px;
}

.image-logo {
  width: 70px;
}

.footer-logo {
  width: 230px;
}

.nav-main {
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  top: 70px;
  color: white;
}

.wp-image-8072 {
  margin: 0 auto;
  width:250px;
}

.image-logo {
  width: 260px
}
header#header {
  position: sticky;
  top: 0;
  z-index: 1005;
  box-shadow: 0px 10px 60px 0px rgba(145, 145, 145, 0.12);
}

.video-overlay-container video {
  position: relative;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 600px;
  background-color: rgba(29, 29, 29, 0.5);
}

.video-overlay-container {
  position: relative;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  /* Make sure text is above overlay */
}

.text-overlay h1 {
  font-size: 54px;
  font-family: sans-serif;
  font-weight: 600;
  color: white !important;
}

.text-overlay p {
  font-size: 20px;
  font-family: sans-serif;
  margin-top: 10px;
}

.header-area-four.six .header-style-four {
  background-color: #2f0202;
}

.header-style-four {
  background-color: rgb(252,241,241);
  padding: 10px 50px;
}

.align-items-center {
  align-items: center !important;
}

.header-style-four .header-left-content ul li {
  display: inline-block;
  font-size: 14px;
}

.home-main {
  position: relative;
}

.header-style-four .header-right-content {
  float: right;
}

.header-style-four .header-right-content .additional-link {
  display: inline-block;
}

.header-style-four .social-links {
  display: inline-block;
}

.header-style-four .header-right-content .additional-link li {
  display: inline-block;
  font-size: 14px;
}

.header-style-four .social-links {
  display: inline-block;
}

.header-style-four .social-links li {
  display: inline-block;
  padding: 0 10px;
}

.header-style-four .header-right-content .additional-link li a {
  color: black;
  font-weight: 500;

  position: relative;
  line-height: 1;
  padding-right: 20px;
}

.header-style-four .header-left-content ul li a {
  color: black;
  font-weight: 500;

  position: relative;
  line-height: 1;
  padding-right: 20px;
}

.header-style-four .header-left-content ul li a svg {
  color: rgb(3 25 157);
  display: inline-block;
  margin-right: 10px;
}

.social-links li a svg {
  color: black;

}

.slider-btn {
  margin: 50px 20px;
}

.default-btn {
  font-size: 16px;
  color: rgb(3 25 157);
  padding: 20px 35px;
  line-height: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  text-transform: capitalize;
  border: 1px solid rgb(3 25 157);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.default-btn.active::before {
  border-top: 20px solid transparent;
  border-left: 20px solid #ffffff;
  border-bottom: 20px solid transparent;
  left: -1px;
}

.default-btn::before {
  content: "";
  position: absolute;
  border-right: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid rgb(3 25 157);
  border-bottom: 20px solid transparent;
  top: 9px;
  left: -1px;
  transition: all 0.5s;
}

.default-btn.active {
  background-color: rgb(3 25 157);
  border-color: rgb(3 25 157);
  color: #ffffff;
}

.default-btn::after {
  position: absolute;
  content: "";
  background-color: rgb(3 25 157);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.5s;
  z-index: -1;
}

.default-btn.active::after {
  width: 100%;
}

.slider-btn .default-btn.white {
  color: #ffffff;
}

.slider-btn .default-btn {
  margin: 0 20px;
}

.default-btn.active:hover {
  background-color: #00000000;
  border-color: rgb(3 25 157);
}

/* .default-btn.active:hover {
  color: rgb(3 25 157);
} */
.default-btn:hover {
  color: #ffffff;
  border-color: rgb(3 25 157);
  background-color: rgb(3 25 157);
}

/* .default-btn.active:hover::before {
  border-top: 20px solid #00000000;
  border-left: 20px solid rgb(3 25 157);
  border-bottom: 20px solid #00000000;
  left: -1px;
} */
.default-btn:hover::before {
  border-top: 20px solid transparent;
  border-left: 20px solid #ffffff;
  border-bottom: 20px solid transparent;
}

.features-section-two {
  position: relative;
  padding: 40px 0 60px;
  overflow: hidden;
  margin: 10px 0 0;
}

.testimonial-image {
  background-image: url(../src/Components/Assets/3.webp);
}

.features-section-two .bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 861px;
  left: auto;
  right: 0;
}

.testimonials-image-two img {
  position: absolute;
  bottom: 0;
  right: 42px;
}

.features-section-two .bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  top: 1px;
  left: 1px;
  opacity: 0.9;
  background-color: #da0b06;
}

.features-section-two .bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  width: 100px;
  left: -1px;
  background-color: #ffffff;
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
  clip-path: polygon(100% 0, 0 0, 0 100%);
}

.testimonial-item {
  background: white;
  padding: 20px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 60px 0px rgba(145, 145, 145, 0.12);
}

@media only screen and (min-width: 480px) {
  .testimonial-item {
    display: flex;
  }
}

.testimonial-item .testi-author {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  z-index: 1;
  width: 90px;
  margin-top: 8px;
  margin-right: 28px;
  position: relative;
}

@media only screen and (max-width: 479px) {
  .testimonial-item .testi-author {
    margin-bottom: 30px;
  }
}

.testimonial-item .testi-author img {
  border-radius: 50%;
}

.text svg {
  color: rgb(3 25 157);
}

.text .svg-one {
  position: relative;
  top: -9px;
  margin-right: 10px;
}

.text .svg-two {
  position: relative;
  top: -7px;
  margin-left: 6px;
}

.testimonial-item .testi-author .rounded-text {
  z-index: -1;
  position: absolute;
  left: -15px;
  top: -15px;
  width: 120px;
  height: 120px;
  transform: rotate(-103deg);
  animation: rotated_circle 12s linear infinite;
}

.testimonial-item .testi-author .rounded-text .coloring {
  font-weight: 600;
  fill: var(--primary-color);
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-content {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.testimonial-item .testi-content .testi-header {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
}

.testimonial-item .testi-content .testi-header .author h5 {
  margin-bottom: -4px;
}

@media only screen and (max-width: 575px) {
  .testimonial-item .testi-content .testi-header .author h5 {
    font-size: 20px;
  }
}

.testimonial-item .testi-content .testi-header .icon {
  line-height: 1;
  font-size: 66px;
  margin-top: -6px;
  color: var(--secondary-color);
}

.testimonial-item .testi-content .text {
  margin-bottom: 8px;
}

@media only screen and (min-width: 576px) {
  .testimonial-item .testi-content .text {
    font-size: 18px;
  }
}

.testimonials-bg-rectangle:after {
  width: 70%;
  height: 70%;
  z-index: -1;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(var(--secondary-rgb), 0.07);
}

.testimonials-two-wrap .section-title {
  max-width: 500px;
}

.testimonials-slider {
  margin-left: -15px;
  margin-right: -265px;
}

@media only screen and (max-width: 1199px) {
  .testimonials-slider {
    margin-right: -15px;
  }
}

.testimonials-slider .testimonial-item-two {
  margin: 15px;
  width: 90% !important;
}

.testimonials-arrow button {
  width: 52px;
  height: 52px;
  font-size: 16px;
  box-shadow: none;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgb(3 25 157);
  position: relative;
  z-index: 9;
}

.testimonials-arrow button:focus,
.testimonials-arrow button:hover {
  color: white;
  background: rgb(3 25 157);
  border-color: white;
}

.testimonials-arrow button:first-child {
  margin-right: 10px;
}

.testimonial-item-two {
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  padding: 37px 40px 28px 40px;
  background: white;
  border-bottom-color: rgb(3 25 157) !important;
  border-radius: 40px;
  border-bottom: solid;
 
}

@media only screen and (max-width: 375px) {
  .testimonial-item-two {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-item-two .text {
  margin-bottom: 28px;
}

@media only screen and (min-width: 376px) {
  .testimonial-item-two .text {
    font-size: 18px;
  }
}

.inner-about {
  padding-left: 118px;
}

.inner-box-order {
  margin: 50px 0px 0px 10px;
}

.testimonial-item-two .testi-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-item-two .testi-footer img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-right: 20px;
}

.testimonial-item-two .testi-footer .author h6 {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .testimonial-item-two .testi-footer .author h6 {
    font-size: 16px;
  }
}

.section-title span {
  padding-top: 20px;
  text-transform: uppercase;
  color: rgb(3 25 157);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.section-title h2 {
  font-size: 40px;
  margin-bottom: 40px;
  position: relative;
  display: block;
  color: rgb(3 25 157);
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}

.section-title {
  max-width: 625px;
  margin: 30px auto 30px;
  text-align: center;
  position: relative;
}

.section-title h2::before {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  content: "";
  width: 50px;
  height: 2px;
  background-color: rgb(3 25 157);
  margin: auto;
}

.section-title h2::after {
  position: absolute;
  bottom: -25px;
  left: -45px;
  right: 0;
  content: "";
  width: 5px;
  height: 2px;
  margin: auto;
  background-color: #ffffff;
  -webkit-animation: moveLeftBounces 10s linear infinite;
  animation: moveLeftBounces 10s linear infinite;
}

.single-service {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  margin-bottom: 30px;
}

.col-lg-4:nth-child(1) .single-service::before {
  background-image: url(../src/Components/Assets/International-move-abroad.webp);
}

.col-lg-4:nth-child(2) .single-service::before {
  background-image: url(../src/Components/Assets/International-freight-company.webp);
}

.col-lg-4:nth-child(3) .single-service::before {
  background-image: url(../src/Components/Assets/International-freight-delivery.webp);
}

.single-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.single-service .service-content-wrap {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-content-wrap svg {
  font-size: 50px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 20px;
  color: rgb(3 25 157);
}

.single-service:hover {
  background-color: transparent;
}

.single-service:hover .service-content-wrap {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.single-service .service-heading {
  text-align: center;
  padding: 20px;
  background-color: rgb(3 25 157);
  position: absolute;
  bottom: 56px;
  left: 0;
  right: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  bottom: -30px;
  -webkit-transform: scale(0);
  transform: scale(0);
  margin-right: 15px;
  margin-left: 15px;
}

.single-service .service-heading h3 {
  color: white;
}

.single-service:hover .service-heading {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.single-service .service-heading .circle-read-more svg {
  color: #ffffff;
}

.circle-read-more svg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
}

.service-all p a {
  color: rgb(3 25 157);
  border-bottom: 1px solid rgb(3 25 157);
}

.service-all p {
  padding-bottom: 20px;
}

.about-section-three {
  position: relative;
  padding: 30px 0 70px;
}

.about-section-three .icon-plane-2 {
  position: absolute;
  top: 170px;
  left: 80px;
}

.bounce-y {
  -webkit-animation: bounce-y 10s infinite linear;
  animation: bounce-y 10s infinite linear;
}

@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.icon-plane-2 {
  width: 82px;
  height: 415px;
  background-image: url(../src/Components/Assets/icon-plane-2.webp);
}

.about-section-three .content-column {
  margin-bottom: 50px;
}

.about-section-three .content-column .inner-column {
  position: relative;
  margin-right: -20px;
}

.about-section-three .content-column .inner-column .sec-title {
  margin-bottom: 20px;
}

.sec-title {
  position: relative;
  margin-bottom: 50px;
}

.sec-title .sub-title {
  position: relative;
  display: block;
  font-size: 14px;
  color: #72727c;
  padding-top: 18px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.sec-title .sub-title:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 8px;
  background-image: url(../src/Components/Assets/icon-sub.webp);
  content: "";
}

.about-section-three .content-column .inner-column .title {
  position: relative;
  color: #da0b06;
  font-weight: 700;
  margin-bottom: 35px;
}

.about-section-three .content-column .inner-column .text {
  position: relative;
  padding-right: 40px;
  margin-bottom: 40px;
}

.icon-box img {
  width: 80px;
  height: 50px;
}

.icon-box {
  display: flex;
}

.inner-column .text {
  position: relative;
  padding-right: 40px;
  margin-bottom: 40px;
}

.icon-box:hover img {
  transform: scaleX(-1);
}

.icon-box img:after {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 69px;
  height: 70px;
  border-radius: 50%;
  background-color: #000000;
  z-index: -1;
}

.btn-style-one {
  position: relative;
  line-height: 1em;
  padding: 23px 49px;
  font-weight: 700;
  overflow: hidden;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #000000;
}

.btn-style-one:hover {
  color: #ffffff;
}

.theme-btn {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  transition: all 300ms ease;
}

.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  color: white;
  background-color: rgb(3 25 157);
  transform: scale(0, 1);
  transform-origin: top right;
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.btn-style-one:hover:before {
  transform: scale(1, 1);
  transform-origin: bottom left;
}

.btn-style-one:hover {
  color: white !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.inner-column {
  position: relative;
  /* margin-top: 5px; */
  padding-left: 15px;
  margin-right: -30px;
}

.inner-column .fact-counter {
  position: relative;
  text-align: right;
}

.counter-block-two {
  visibility: visible;
  animation-name: fadeInUp;
}

.counter-block-two {
  margin-bottom: 40px;
}

.counter-block-two .inner-box {
  position: relative;
  display: inline-block;
  padding: 10px 0 0 90px;
  padding-right: 145px;
  padding-bottom: 60px;
  border-bottom: 1px solid #d7e0e5;
  text-align: right;
}

.counter-block-two .inner-box img {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  width: 121px;
  height: 123px;
  font-size: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--theme-color1);
  background-color: #f8f5f1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.counter-block-two .inner-box .counter-one {
  position: relative;
  color: #00294b;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 0;
}

.counter-block-two .inner-box:hover img {
  background-color: #f7d7d7;
}

.inner-column .exp-box {
  position: absolute;
  bottom: -15px;
  right: -20px;
  display: inline-block;
  padding: 55px 68px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #da0b06;
  text-align: center;
}

.about-section-three .image-column .inner-column .exp-box .icon-building-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.icon-building-2 {
  width: 271px;
  height: 306px;
  background-image: url(../src/Components/Assets/icon-building-2.webp);
}

.inner-column .exp-box img {
  position: relative;
  display: inline-block;
  color: #ffffff;
  width: 65px;
  line-height: 1em;
  transition: all 300ms ease;
}

.overlay-anim:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}

.inner-column .exp-box .count {
  position: relative;
  display: block;
  color: #ffffff;
  font-weight: 800;
  font-size: 60px;
}

.about-section-three .image-column .inner-column .exp-box .text {
  position: relative;
  color: #ffffff;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.service-block-two .inner-box {
  position: relative;
  margin-top: 108px;
  padding: 130px 50px 50px;
  background-color: #0a0a1c;
  text-align: center;
}

.service-block-two .inner-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  height: 0;
  background-color: #da0b06;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.service-block-two .inner-box .image {
  position: absolute;
  top: -108px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  margin-bottom: 0;
  z-index: 3;
}

.service-block-two .inner-box:hover:before {
  height: 100%;
}

.service-block-two .inner-box:hover .image:before {
  background-color: #ffffff;
}

.bg-pattern-4 {
  background-image: url(../src/Components/Assets/pattern-4.webp);
  padding: 20px 20px 40px;
}

.service-block-two .inner-box .image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 216px;
  height: 216px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: -1;
}

.service-block-two .inner-box .content {
  position: relative;
}

.service-block-two .inner-box .content .title {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.service-block-two .inner-box .content .title a {
  color: #ffffff;
}

.service-block-two .inner-box .content .text {
  position: relative;
  color: #cecece;
  letter-spacing: 0.5px;
  text-align: justify;
  transition: all 300ms ease;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: white !important;
}

.service-three {
  width: 280px;
  margin-top: 45px;
  margin-left: 44px;
}

.section-second span {
  padding-top: 20px;
  text-transform: uppercase;
  color: rgb(3 25 157);
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-align: center;

  margin-bottom: 10px;
}

.section-second h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  display: block;
  color: #ffff;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
}

.clients-section {
  padding: 30px 0;
  overflow: hidden;
}

.why-choose-us-two {
  position: relative;
  margin: 60px 0 130px;
}

.bg-pattern-7 {
  background-image: url(../src/Components/Assets/logistics-and-transport-opt1.jpg);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.why-choose-us-two .image-column  img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-column-second  img {
  position: absolute;
  display: block;
  object-fit: cover;
  top: 190px;
  width: 400px;
}


.why-choose-us-two .content-column .inner-column .sec-title .text {
  position: relative;
  color: #9ea8b1;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  padding-right: 40px;
  letter-spacing: 0.4px;
}

.why-choose-us-two .content-column .inner-column .sec-title h2 {
  position: relative;
  color: #9ea8b1;
  font-size: 48px;
  font-weight: 700;
  padding: 10px 0;
  line-height: 60px;
  padding-right: 40px;
  letter-spacing: 0.4px;
}



.why-choose-us-two .image-column .inner-column .icon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -60%);
  transform: translate(-40%, -60%);
}


.why-choose-us-two .content-column .inner-column {
  position: relative;
  padding: 120px 0 70px;
}

.why-choose-us-two .content-column .inner-column .sec-title {
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: 1px solid #262639;
}

.why-choose-us-two .content-column .inner-column .title-box .image {
  position: absolute;
  top: 0;
  left: 0;
}

.why-choose-us-two .content-column .inner-column .title-box .title {
  position: relative;
  display: block;
  color: #ffffff;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
}

.why-choose-us-two .content-column .inner-column .title-box {
  position: relative;
  padding-left: 185px;
  margin-bottom: 90px;
}

.why-choose-us-two .content-column .inner-column .title-box .title span {
  color: rgb(3 25 157);
}

.why-choose-us-two .image-column .inner-column {
  position: relative;
  padding-left: 70px;
  margin-right: -366px;
}

.choose-block-two .inner-box .content .title {
  position: relative;
  color: #9ea8b1;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  font-size: 18px;
  margin-bottom: 0;
}

.choose-block-two .inner-box img {
  position: relative;
  display: inline-block;
  width: 80px;
  line-height: 1em;
  margin-bottom: 10px;
  color: rgb(3 25 157);
  transition: all 300ms ease;
}

.choose-block-two .inner-box img:before {
  position: relative;
  display: inline-block;
  transition: all 300ms ease;
}

.choose-block-two .inner-box img:after {
  content: "";
  position: absolute;
  top: -15px;
  right: -25px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: black;
}

.choose-block-two .inner-box:hover img:before {
  transform: scaleX(-1);
}

.client-block img:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;
  transform: translateY(50%) scaleY(1);
  opacity: 0;
  filter: blur(10px);
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.client-block img {
  position: relative;
  width: auto !important;
  display: inline-block !important;
  transition: all 500ms ease;
  filter: grayscale(1);
}

.client-block .image:hover img:first-child {
  transform: translateY(0) scaleY(1);
  opacity: 1;
  filter: blur(0);
}

.client-block img {
  position: relative;
  width: auto !important;
  display: inline-block !important;
  transition: all 500ms ease;
  filter: grayscale(1);
}

.client-block .image:hover img:nth-child(2) {
  transform: translateY(-50%) scaleY(1);
  opacity: 0;
  filter: blur(10px);
}

.client-block {
  position: relative;
  overflow: hidden;
  text-align: center;
}

/* Footer Styles */

.widgets-section {
  padding: 50px 0 0;
  background-color: #09172E;
  position: relative;
  overflow: hidden;
}

.footer-column {
  margin-bottom: 30px;
}

.footer-widget {
  color: #ffffff;
  position: relative;
  z-index: 999;
  padding: 30px;
}

.footer-widget.about-widget .logo img {
  max-width: 100%;
  background: white;
  padding: 15px;
}

.footer-widget.about-widget .theme-btn {
  display: inline-block;
  background-color: rgb(3 25 157);
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.copyright-text {
  color: white;
}

.footer-widget .text {
  margin: 30px 0;
}

.btn-title {
  position: relative;
  z-index: 12;
}

.footer-widget.links-widget .user-links li {
  margin-bottom: 10px;
}

.footer-widget.links-widget .user-links li a {
  color: white;
  text-decoration: none;
}

.footer-widget.links-widget .user-links li a:hover {
  color: rgb(3 25 157);
}

.privacy{
  display: flex;
  align-items: center;
  text-align: justify;
  gap: 20px;
  padding: 50px 0;
}

.privacy-content h2{
  font-size: 30px;
  margin-bottom: 10px;
}

.widget-title {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 25px;
  padding-bottom: 14px;
}
.text-footer{
  margin-bottom: 15px;
}
.footer-widget.newsletter-widget .text {
  margin-bottom: 15px;
  font-size: 14px;
  color: white;
}

.footer-widget.newsletter-widget .newsletter-form {
  display: flex;

}

.newsletter-form .form-group {
  position: relative;
  width: 100%;
}

.newsletter-form .form-group input:not([type="submit"]),
.newsletter-form .form-group textarea,
.newsletter-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 20px;
  margin-bottom: 0;
  border-radius: 5px;
  color: #999;
  border: 1px solid #2d2d2d;
  background-color: transparent;
  transition: all 300ms ease;
}

.newsletter-form .form-group button:hover {
  background-color: #da0b06;
}

.newsletter-form .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  background-color: rgb(3 25 157);
  transition: all 300ms ease;
}

.seconds-title p {
  text-align: justify;
}

.bg-image {
  background-image: url(../src/Components/Assets/footer-bg-main-1.png);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.widget-content ul li {
  list-style: none;
  padding: 0;
}

.widget-content ul li svg {
  margin-right: 10px;
}

.widget-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, rgb(3 25 157), #000000);
}

.insta-gallery a:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #da0b06;
  opacity: 0.8;
  content: "";
  transform: scale(0);
  transition: all 300ms ease;
}

.insta-gallery a:after {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  content: "+";
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  color: #ffffff;
  font-family: "font awesome 6 pro";
  transform: scale(0);
  transition: all 300ms ease;
}

.insta-gallery img {
  display: block;
  width: 100%;
}

.insta-gallery .image {
  position: relative;
  float: left;
  width: 33.3333%;
  padding: 0 8px;
  margin-bottom: 16px;
  border-radius: 15px;
  overflow: hidden;
}

.insta-gallery a {
  position: relative;
  display: block;
}

.insta-gallery a:hover:before {
  transform: scale(1);
}

.insta-gallery a:hover:after {
  transform: scale(1);
}
.bg-dark img {
  width: 100%;
}
.footer-bottom {
  position: relative;
  width: 100%;
  padding-top: 20px;
}

.footer-bottom .inner-container {
  position: relative;
  background: #09172E;
  padding: 25px 0;
  border-top: 1px solid rgb(255 255 255 / 12%);
  border-radius: 10px 0 0;
  /* padding-left: 120px; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.footer-bottom .inner-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: -100%;

  width: 100%;
  height: 100%;
  background-color: #000000;
}

.footer-bottom .inner-container .scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 45px;
  height: 40px;
  line-height: 35px;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  display: none;
  color: #fff;
  background-color: rgb(3 25 157);
  transition: all 300ms ease;
  text-align: center;
  z-index: 4;
  
}
.footer-bottom .inner-container .scroll-to-top  svg{
  animation: blinker 1s linear infinite
}
@keyframes blinker{
  0% {
    opacity: 1;
}
50% {
    opacity: .3;
}
100% {
    opacity: 1;
}
}
.social-icon-one {
  position: relative;
  display: flex;
  gap: 25px;
  list-style: none;
  flex-wrap: wrap;
}


.social-icon-one li a {
  color: white;
  background-color: #313c44;
  padding: 6px 10px 10px 11px;
  border-radius: 50%;

}

.scroll-to-top.scroll-to-target svg {
  font-size: 24px;
}
.contact-details__right .sec-title h2 {
  color: white;
}


.contact-details {
  padding: 70px 0;
  border-bottom: 2px solid #2d2b3e;
  margin-bottom: 15px;
  background-color: #14212A;
  position: relative;
}
.overlay-second{
  background-image: url(../src/Components/Assets/pattern-img.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  opacity: 0.49;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.ml-25 {
  margin-left: 25px !important;
}

.contact-details__info li .icon {
  height: 80px;
  width: 80px;
  background-color: rgb(3 25 157);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  color: white;
  font-size: 20px;
}
/* .form-svg{
  transform: rotate(180deg);
  fill: white;

}
.form-svg svg{
  position: absolute;
  bottom: 0;  

} */

.contact-details__info li {
  position: relative;
  display: flex;
  margin-bottom: 35px;
  align-items: center;
}

.contact-details__info li .text {
  margin-left: 30px;
}

.contact-details__info li .text a {
  font-size: 18px;
  color: #000000;
  transition: all 500ms ease;
}

.contact-details__info li .text h6 {
  margin-bottom: 10px !important;
  font-size: 20px;
}

.contact-details__info li .icon:hover {
  color: black;
}

.contact-details__right .sec-title .text {
  color: #DFDFDF
}
.contact-details__info li .text{
  color: white;
}
.contact-details__left {
  background: white;
  padding: 49px 45px 15px;
  border-radius: 20px;
  margin: 10px;
}
.contact-details__info li .text a{
  color: #DFDFDF
}

.bg-dark {
  position: relative;
}
.text-center-two p {
  font-size: 22px;
  font-weight: 600;
}
.footer-contact-widget{
  padding: 24px 0;
  position: relative;
  text-align: center;
  background-color: #d70006;
  transform: skewX(-17deg);
  border-bottom: 2px solid rgb(255 255 255 / 12%);
}
.footer-contact-widget a.phone {
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 3px;
  font-weight: 600;
  letter-spacing: -1px;
  display: inline-block;
  color: #fff;
  transition: all .3s;
  transform: skewX(16deg) 
}
.footer-contact-widget div.title {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: .5px;
  transform: skewX(16deg) 
}

.text-center-two {
  position: absolute;
  top: 30%;
  width: 17%;
}

.title-outer {
  position: absolute;
  top: 85px;
  margin-left: 90px;
}

.text-center-two span {
  font-size: 22px;
  border: dashed 1px;
  border-radius: 50%;
  color: #d70006;
  padding:14px 20px 19px;
}

.bg-white {
  background: black !important;
  height: 85px;
}

.page-title {
  background-image: url(../src/Components/Assets/page-title.webp);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 300px;
}
.page-content{
  padding: 20px 0 30px;

}
.banner-section{
  display: flex;
  position: relative;
  align-items: center;
}
.banner-section img {
  width: 500px;
  height: 306px;
  padding: 20px 0;
}

.page-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #131313;
  opacity: 0.6;
  content: "";
}

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent background */
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it is above other content */
}

.side-menu.open {
  transform: translateX(0);
}

.side-menu.closed {
  transform: translateX(-100%);
}

/* Style for active nav link */
/* Style for active nav link */
/* Style for active nav link */
.navbar-nav .nav-item.active .nav-link {
  position: relative;
}

.main-navbar {
  z-index: 1;
}

.navbar-nav .nav-item.active .nav-link::before {
  content: "";
  position: absolute;
  bottom: -2px;
  /* Adjust as needed */
  left: 0;
  width: 100%;
  height: 2px;
  /* Thickness of the underline */
  background-color: #af2523
    /* Color of the underline */
  ;
}

.navbar-nav>li>a {
  padding: 8px 10px !important;
}



/* Additional styles (optional) */
.navbar-nav .nav-item .nav-link {
  color: #0f5370;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #1b1b1b;
  /* Color for hover state */
}

/* You can adjust these styles according to your design preferences */

.menu-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}

.page-title .title {
  position: relative;
  font-size: 60px;
  color: #fff;
  margin-bottom: 17px;
}

.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 40px;
  color: #fff;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
  text-transform: capitalize;
}

.page-breadcrumb li a {
  color: #ffbf43;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.about-section {
  position: relative;
  padding: 120px 0 80px;
}

.about-section .icon-plane-8 {
  position: absolute;
  top: -60px;
  right: 184px;
}

.bounce-x {
  -webkit-animation: bounce-x 10s infinite linear;
  animation: bounce-x 10s infinite linear;
}

.icon-plane-8 {
  width: 282px;
  height: 102px;
  background-image: url(../src/Components/Assets/icon-plane-8.webp);
}

@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.about-section .image-column.style-two .inner-column-about .image-box {
  position: relative;
}

.image-box .exp-box-second {
  position: absolute;
  top: -150px;
  right: 100px;
  width: 220px;
  padding: 35px 39px 29px;
  border-radius: 10px;
  text-align: center;
  background-color: rgb(3 25 157);
  text-align: left;
  z-index: 4;
}

.image-box .exp-box-second .title {
  font-size: 35px;
  color: white;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;

  margin-bottom: 0;
}

.exp-box-second span {
  color: white;
  display: block;
  margin-top: -3px;
  text-align: center;
}

.bounce-y {
  animation: bounce-y 10s infinite linear;
}

@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.inner-column-about .image-box img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-anim:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}

.image-box .exp-box-second img {
  width: 80px;
  margin: 0 auto;
}

.about-section .image-column.style-two .inner-column-about {
  position: relative;
  margin-left: 74px;
  margin-right: -52px;
  margin-top: 55px;
  text-align: right;
}

.about-section .content-column .inner-column-about {
  position: relative;
  padding-left: 62px;
  margin-top: 19px;
}

.second-title {
  position: relative;
  margin-bottom: 50px;
}

.seconds-title .sub-title {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: rgb(3 25 157);
  margin-bottom: 15px;
  text-transform: uppercase;
}

.seconds-title h2 {
  position: relative;
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 20px;
  z-index: 2;
}

.seconds-title span {
  color: rgb(3 25 157);
}

.outer-box {
  position: relative;
}

.process-section {
  position: relative;
  background-image: url(../src/Components/Assets/9.webp);
  height: auto;
  bottom: 0;
  padding: 100px;
}

.bg-about {
  padding: 50px 0;
}

.sec-about .sub-icon {
  position: relative;
  width: 70px;
  height: 13px;
  background-image: url(../src/Components/Assets/icon-sub.webp);
  margin-bottom: 5px;
  display: block;
  background-repeat: no-repeat;
}

.sec-about .sub-icon {
  margin: 0 auto 5px;
  text-align: center;
}

.sec-about {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.icon-box-about {
  position: relative;
  height: 160px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 29px;
  border-radius: 50%;
 
  color: white;
  text-align: center;
  z-index: 9;border: 2px solid #ededed;
}

.icon-box-parent::before {
  content: "";
  position: absolute;
  top: -14px;
  right: 56px;
  width: 160px;
  height: 160px;
  border: 1px solid #bcbcbc;
  border-radius: 50%;
  z-index: 0;
}

.icon-box-parent {
  position: relative;
}

.service-all {
  text-align: center;
}

.process-section .outer-box .icon-line {
  position: absolute;
  top: 65px;
  left: 50%;
  background-image: url(../src/Components/Assets/icon-line.webp);

  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}



.process-block .inner-box .content-box .title {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 9px;
}
.process-block-img{
  position: relative;
}
.process-block-img::before{
  background-image: url(../src/Components/Assets/process-arrow-1.webp);
    position: absolute;
    content: '';
    right: -50px;
    top: 50px;
    width: 102px;
    height: 36px;
    background-repeat: no-repeat;
}
.process-block-image{
  position: relative;
}
.process-block-image::before{
  background-image: url(../src/Components/Assets/process-arrow-2.webp);
    position: absolute;
    content: '';
    right: -50px;
    top: 50px;
    width: 102px;
    height: 36px;
    background-repeat: no-repeat;
}

.process-block .inner-box .content-box .text {
  text-align: center;
}

.process-block .inner-box:hover .image-about img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.icon-box-about .image-about img {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.process-section .btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.process-section .btn-box .contact-btn svg {
  position: relative;
  height: 44px;
  width: 44px;
  line-height: 44px;
  padding: 10px;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: #ffbf43;
  text-align: center;
  transition: all 300ms ease;
}

.process-section .btn-box .contact-btn {
  position: relative;
  color: black;
  font-weight: 700;
  margin-left: 45px;
}

.icon-box-about .image-about img {
  position: relative;
  display: block;
  margin: 20px 0 0px;
  height: 100%;
  width: 100%;
  transition: all 300ms ease;
}

.icon-box-about .count {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  background-color: rgb(3 25 157);
  border-radius: 50%;
  display: inline-block;
}

.work-section {
  padding: 70px 0 50px;
}

.work-section .outer-box {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.work-section .work-block {
  min-height: 470px;
  margin-right: 5px;
  margin-left: 5px;
  flex: 1;
  will-change: flex;
}

.work-block {
  position: relative;
  margin-bottom: 30px;
  transition: 0.8s cubic-bezier(0.29, 0, 0.2, 1);
}

.work-block .image-box .image img {
  width: 100%;
  height: 470px;
  object-fit: cover;
  border-radius: 10px;
  transition: all 300ms ease;
}

.work-block .inner-box {
  position: relative;
  overflow: hidden;
}

.work-block .image-box {
  position: relative;
  transition: all 300ms ease;
}

.work-block .image-box .image {
  position: relative;
  display: block;
  margin-bottom: 0;
}

.work-block .image-box .image::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(29, 29, 29, 0.9);
  opacity: 0.3;
  transition: all 300ms ease;
  z-index: 2;
  border-radius: 10px;
}

.work-block .content-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  z-index: 3;
}

.work-block .content-box .cat {
  position: relative;
  font-weight: 700;
  font-family: var(--text-font);
  color: white;
  display: inline-block;
  opacity: 0;
  font-size: 16px;
  transform: translateY(20px);
  transition: all 300ms ease;
  margin-bottom: 8px;
}

.work-block .content-box h4 {
  position: relative;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
  padding: 0 18px;
  color: white !important;
  transform: translateY(20px);
  opacity: 0;
  margin-bottom: 20px;
  transition: all 300ms ease;
}

.work-block .content-box h4 a {
  color: white !important;
}

.work-block .content-box-hover h4 a {
  color: black !important;
}

.work-block .content-box .read-more {
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  opacity: 0;
  border-radius: 50%;
  display: inline-block;
  color: #000000;
  background-color: #ffbf43;
  text-align: center;
  transform: scale(0);
  transition: all 300ms ease;
}

.work-block .content-box .read-more svg {
  font-size: 16px;
}

.work-block .content-box-hover {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  color: white !important;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  z-index: 2;
}

.work-block .content-box-hover .title {
  position: relative;
  color: #111;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 45px 20px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  writing-mode: vertical-rl;
  border-radius: 10px;
  background: #ffbf43;
  text-align: start;
  transform: rotate(180deg);
  transition: all 300ms ease;
}

.work-block .inner-box:hover .content-box .cat {
  opacity: 1;
  transform: translate(0);
  transition-delay: 400ms;
}

.work-block .inner-box:hover .content-box .title {
  opacity: 1;
  transform: translate(0);
  transition-delay: 600ms;
}

.work-block .inner-box:hover .content-box .read-more {
  opacity: 1;
  transform: scale(1);
  transition-delay: 800ms;
}

.work-block .inner-box:hover .content-box-hover .title {
  opacity: 0;
  transform: translateY(100);
  transition-delay: 200ms;
}

.work-section .work-block:hover,
.work-section .work-block.active {
  flex: 2;
}

.inner-box-order-two:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 2px solid white;
}

.inner-box-order-two {
  position: relative;
  display: inline-block;
  width: 101px;
  height: 101px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 44px;
  margin-bottom: 20px;
  border-radius: 50%;
  color: var(--theme-color-dark);
  background-color: #ffbf43;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

img.icon {
  width: 50px;
  padding-top: 5px;
  padding-right: 0px;
  filter: brightness(0) invert(1);
}

img.icon:hover {
  filter: brightness(0) invert(1);
}

.inner-box-order-two:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  color: var(--theme-color-light);
  background-color: rgb(3 25 157);
}

.call-to-action {

  position: relative;
}

.call-to-action:before {
  content: "";
  position: absolute;
  bottom: -70px;
  right: 0;
  width: 720px;
  height: 465px;
  clip-path: polygon(100% 100%, 100% 0, 0% 100%, 0 100%);
  background-color: rgb(3 25 157);
  z-index: -1;
}



.bg-pattern-1 {
  background-image: url(../src/Components/Assets/pattern-1.webp);
  height: 100%;
  width: 100%;
  display: flex;
  margin: 70px 0;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 100px;
  align-items: center;
}


.image-box-contact img {
  width: 500px;
  height: 300px;
}

.content-box-contact {
  padding-right: 40px;
}

.content-box-contact .text {
  margin: 30px 0;
  font-size: 20px;
}

.btn-box-contact .theme-btn {
  margin: 0 10px;
}



.services-section {
  margin: 50px 0;
}


.outer-box-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.outer-box-service h2 {
  padding: 0 20px 20px;
  font-size: 24px;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.service-block {
  flex: 1 1 calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  text-align: center;
  transition: transform 0.3s ease;
}

.service-block:hover {
  transform: translateY(-10px);
}

.inner-box-service {
  padding: 15px;
}

.inner-box-service img {
  width: 100%;
  height: 270px;
  display: block;
  object-fit: cover;
  border-radius: 15px;
}

.content-service {
  padding: 20px 10px 0px;
}

.icon-service {
  font-size: 40px;
  margin-bottom: 10px;
}

.title-service {
  font-size: 20px;
  margin: 10px 0;
  color: rgb(3 25 157) !important;
}

.title-service a {
  color: #333;
  text-decoration: none;
}

.text-service {
  font-size: 16px;
  text-align: justify;
  color: #666;
  margin-bottom: 15px;
}

button#dropdown-basic\ dropdown-border {
  border: 0;
  padding: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

.read-more i {
  margin-left: 5px;
}

@media (max-width: 992px) {
  .service-block {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .service-block {
    flex: 1 1 100%;
  }
}

img.air-flight {
  position: absolute;
  bottom: -200px;
  width: 300px;
  left: 24%;
}
.air-flight {
  position: relative;
  animation: moveRightLeft 5s infinite alternate;
}

@keyframes moveRightLeft {
  0% {
      left: 0;
  }
  100% {
      left: 100px; /* Adjust this value as needed */
  }
}

@media only screen and (max-width: 768px) {
  .page-content {
    padding: 20px 0 0px !important;
}
  .page-breadcrumb li {
    margin-right: 0px !important;
    padding-right: 0px !important;
}
  .banner-section img {
    width: 100% !important;
}
  .banner-section {
    display: block !important;
}
  .text-center-padding {
    padding-top: 70px !important;
}
  .inner-about-main {
    margin-left: 0px !important;
}
  .about-bottom{
    position: unset !important;
  }
  .section-bottom-left{
    position: unset !important;
  }
  .about-service-main {
    align-items: normal !important;
}
  .about-content-main {
    display: block !important;
    position: unset !important;
}
  .about-main-section {
    background-size: 100% 100% !important;
    margin: 10px 0 !important;
}
  .title-wrap h2 {
    font-size: 35px !important;
  }
 
  .header-style-four {
    display: none !important;
}
  .hero-main-image{
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    background-size: 100%;
    height: 100% !important;
}
img.air-flight {
  bottom: -147px !important;
  width: 200px !important;
}
  .hero-main {
    height: 920px !important;
}
  .sec-title-main h2 {
    font-size: 39px !important;
    font-weight: 700;
    color: black;
}
  .sec-title-main {
    width: 100% !important;
    margin-top: 410px !important;
    margin-left:0px !important;
}
  .feature-box {
    padding-left: 30px;
    padding-right: 30px !important;
}
  .feature-box-border {
    border-right: none !important; 
    padding-right: 30px !important; 
    padding-left: 30px !important;
}
  .outer-box {
    padding-bottom: 50px !important;
}
  .btn-box{
    margin: 30px 0 !important;
    text-align: center;

  }
  .time-one_image.now-in-view {
    margin: 0 0 0 !important;
}
  .title-main {
    margin: 0 auto !important;
}
  .clearfix div  img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    position: unset !important;
    border-radius: 20px;
    margin-bottom: 20px !important;
  }
  .clearfix{
    /* margin-bottom: 30px; */
  }
  .sec-title{
    text-align: center;
  }
  .media-body {
    position: relative;
    text-align: center !important;
}
  
  .post-thumb {
    width: auto !important;
    text-align: center;
}
  .service-icon-img-wrap {
    position: unset !important;
    margin: 10px auto !important;
   
}
  .media {
  
    display: block !important;
    
}
  .why-choose-us-two {
    position: relative;
    margin: 0px !important;
}
  .why-choose-us-two .image-column  img {
    width: auto;
    height: 850px !important;
}
.feature {
  margin: 0 0 40px !important;
}
  .scrub-each {
    font-size: 20px !important;

}
  .image-column-second  img {
    display: none !important;
}
  .second-feature {
    display:block !important;
    padding: 40px 0;
}
  .feature-box-img img {

    margin-top: 0px !important;
}
  .feature-box-wrapper {
    display: block !important;
    margin-top: 0px !important;
  }
  .main-feature {
    display: block !important;
}
  .feature-chhose-main {
    display: block !important;
    padding: 52px !important;
}

  .process-block-img::before {
    background-image: none !important;
    position: unset !important;
   
}
.process-block-image::before {
    background-image: none !important;
    position: unset !important;
   
}
  .dropdown-main{
    position: relative !important;
      background: white !important;
      width: 300px !important;
      padding: 15px 25px  !important;
  }
  .header-right-content {
    display: none;
  }

  .header-area-four .header-left-content {
    text-align: center;
  }

  .header-area-four .header-left-content ul li a {
    padding-right: 8px;
  }

  .prevoz-slider-item .prevoz-slider-text {
    text-align: center;
    margin-top: 60px;
  }

  .prevoz-slider-item .prevoz-slider-text p {
    font-size: 14px;
    margin-bottom: 40px;
    margin-bottom: 30px;
  }

  .slider-btn .default-btn {
    margin: 0 10px !important;
  }

  .privacy{
    display: block;
    
  }
  .page-title .title {
    font-size: 48px !important;
}
  .page-title {
    padding: 0px !important;
    min-height: 260px !important;
}
  .privacy img {
    margin: 0 auto;
    display: flex;
}
  .slider-btn {
    text-align: center;
    margin-top: 60px;
  }

  .prevoz-slider-item .prevoz-slider-text .slider-btn .default-btn {
    margin: 0 !important;
  }

  .default-btn {
    font-size: 14px;
    padding: 12px 20px;
  }

  .default-btn.active::before {
    border-top: 10px solid transparent;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
    top: 10px;
  }

  .default-btn::before {
    border-top: 10px solid transparent;
    border-left: 10px solid rgb(3 25 157);
    border-bottom: 10px solid transparent;
    top: 10px;
  }

  .prevoz-slider-item .prevoz-slider-text .slider-btn .default-btn {
    margin: 0 !important;
  }
  .order-review {
    width: 100% !important;
    padding-top: 50px !important;
}
  .text-overlay {
    position: absolute;
    top: 22%;
    left: 0;
    transform: none;
    color: white;
    text-align: center;
    z-index: 1;
  }
  .checkout-form {
    display: block !important; 
}
  .checkout-review {
    padding: 0 !important;
    width: 100% !important;
}
  .text-overlay h1 {
    font-size: 33px;
  }
  .section-wrapper {
    margin-right: 0px !important;
}
.bg-about {
  padding: 20px 0 !important;
}
  .header-left-content ul {
    display: flex;
    justify-content: center;
  }

  .header-style-four {
    padding: 10px 0px 10px 5px;
  }

  .about-section-three {
    position: relative;
    padding: 50px 0 0px;
  }

  .inner-column {
    padding: 0 !important;
    margin: 0px !important;
  }

  .inner-column .exp-box {
    right: 62px !important;
  }

  .inner-column .fact-counter {
    text-align: center !important;
    margin-top: 50px;
  }

  .about-section-three .content-column .inner-column .text {
    padding-right: 0px !important;
  }

  .icon-box {
    display: flex;
    gap: 24px !important;
  }

  .image-column {
    overflow: hidden;
  }

  .service-block-two .inner-box {
    margin-top: 150px !important;
  }

  .counter-block-two .inner-box {
    position: relative;
    display: inline-block;
    padding: 20px 0 0;
    padding-right: 180px;
    padding-bottom: 60px;
    border-bottom: 1px solid #d7e0e5;
    text-align: center;
  }

  .why-choose-us-two .content-column .inner-column .sec-title {
    padding: 70px 0 2px 30px !important;
  }

  .why-choose-us-two .content-column .inner-column .title-box .image {
    position: absolute;
    left: 20px;
  }
  .footer-widget.about-widget .logo{
    text-align: center;
  }
  .why-choose-us-two .content-column .inner-column .title-box {
    padding-left: 200px !important;
    margin-bottom: 0px !important;
  }

  .inner-box {
    text-align: center;
    padding-top: 30px;
  }

  .seconds-title h2 {
    font-size: 30px !important;
  }

  .why-choose-us-two .image-column .inner-column {
    display: none;
  }

  .why-choose-us-two .content-column .inner-column .sec-title h2 {
    font-size: 25px !important;
    line-height: normal;
  }
  .about-main-section .about-second-image {
    display: none !important;
}
  .chhose-content {
    top: 6% !important;
    margin: 0 auto;
}
  .bg-pattern-7 {
    height: 850px !important;
  }

  .testimonials-image-two img {
    display: none;
  }

  .testimonials-slider .testimonial-item-two {
    width: 83% !important;
    margin: 30px !important;
}

  .features-section-two {
    margin: 0 !important;
  }

  .section-title h2 {
    color: black !important;
    font-size: 22px;
  }
  .bg-dark img {
    width: 100%;
    height: 100% !important;
    position: absolute !important;
    object-fit: cover !important;
}
.contact-details__left {
  padding: 34px 15px 1px !important;
  margin: 0 !important;
}
.form-control {
  height: 49px !important;
  margin-bottom: 19px !important;
}
.text-center {
  position: unset;
  margin-bottom: 60px;
  margin-left: 34px;
  width: 80%;
  position: relative !important;
  /* text-align: center; */
}
  .section-title {
    margin: auto 30px !important;
}
  .bg-dark svg {
    height: auto !important;
  }

  .bg-dark {
    position: relative;
    padding: 0 0 40px;
  }

  .footer-bottom .inner-container {
    padding: 18px !important;
    display: block;
}
  .social-icon-one li a {
    font-size: 20px;
  }

  .social-icon-one {
    display: flex;
    gap: 28px;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-choose-us-two {
    padding: 0 0 50px 0px;
  }

  .contact-details__info li .text {
    margin-left: 15px !important;
  }


  .padding-home {
    padding: 20px !important;
  }

  .about-section .icon-plane-8 {
    position: absolute;
    top: -40px;
    right: 40px;
  }

  .about-section .content-column .inner-column-about {
    position: relative;
    padding: 0 20px !important;
    margin-top: 19px;
    padding-bottom: 20px;
  }

  .inner-column-about .image-box img {
    padding: 20px !important;
  }

  .inner-about {
    padding-left: 0px;
    text-align: center;
  }

  .image-width {
    width: 100% !important;
  }

  .inner-box-order {
    margin: 50px 10px 0px 10px !important;
    padding: 0px 30px;
  }

  .inner-box-order-two {
    margin: 0 auto 20px;
  }

  .about-section .image-column.style-two .inner-column-about {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }

  .header-style-four .header-left-content ul li {
    display: inline-block;
    font-size: 10px !important;
  }

  .image-box .exp-box-second .title {
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 0;
  }

  .image-box .exp-box-second {
    top: 50px;
    right: 31px;
    width: 160px;
    height: 160px;
    padding: 0px;
  }

  .image-box .exp-box-second img {
    width: 85px;
    margin: 0 auto;
    height: auto !important;
  }

  .about-section {
    padding: 121px 0 10px !important;
    overflow: hidden;
  }

  .process-section {
    padding: 0px !important;
  }

  .process-block:nth-child(even) {
    margin-top: 0px !important;
  }

  .work-section .outer-box {
    display: block !important;
  }

  .sec-title {
    margin-bottom: 20px !important;
  }

  .btn-style-one {
    padding: 23px 20px !important;
  }

  .process-section .btn-box .contact-btn {
    margin-left: 24px !important;
  }

  .process-section .btn-box {
    padding-bottom: 50px !important;
  }

  .navbar-nav>li>a {
    padding-top: 15px !important;
    padding-bottom: 7px;
  }

  .mobile-view {
    display: none;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  div.product {
    padding: 30px 30px;
    width: 100%;
    display: block !important;
    background: #f5f9ff;
    align-items: center;
    max-width: 1080px;
    gap: 30px;
    margin: 0px !important;
  }

  .payment-image {
    width: 100% !important;
    /* padding: 0 !important; */
  }

  .bg-pattern-4 {
    padding: 0px !important;
  }

  .product-text {
    padding: 50px 0 0 0 !important;
  }

  .product-label {
    width: 100% !important;
  }

  button.single_add {
    margin-top: 25px !important;
  }

  .contact-details {
    margin-bottom: 0px !important;
  }

  .contact-details__info li .icon {
    height: 58px !important;
    width: 58px !important;

  }

  .image-logo {
    width: 180px !important;
  }

  .service-page1-right {
    margin-left: 0px !important;
    margin-top: 43px !important;
  }

  .contact-details__info li .text h6 {
    margin-bottom: 5px !important;
    font-size: 18px !important;
  }

  .contact-details__info li .text a {
    font-size: 17px !important;
  }

  .our-services {
    overflow: hidden;
  }

  .bg-pattern-1 {
    display: block !important;
    padding: 40px !important;
  }

  .image-box-contact img {
    width: 100%;
    height: auto;
  }

  .btn-box-contact {
    display: flex;
  }

  .btn-box-contact .theme-btn {
    margin: 0 1px;
  }

  .image-box-contact img {
    margin-top: 35px;
  }


  .angle-down {
    position: absolute;
    font-size: x-large;
    right: 0;
    top: 5px;
    color: white;
  }

  .outer-box-service h2 {
    padding: 0 20px 10px;
    font-size: 18px;
    text-align: justify;
    line-height: 1.7;
  }

  .nav__close i {
    color: white !important;
  }

  .nav__link span {
    color: white !important;
  }

  .nav__link span:hover {

    color: white !important;
  }
}

.our-services {
  overflow: hidden;
}

.nav-width {
  width: 90%;
}

ul.navbar-main li {
  position: relative;
}

ul.navbar-main li a::after {
  position: absolute;
  top: auto;
  right: -16px;
  line-height: 1;
  content: "+";
  font-family: "Font Awesome 6 Pro";
  font-size: 17px;
  transition: all 0.1s ease-in-out 0s;
  background-image: linear-gradient(90deg, #20d9a1 0%, #5f39ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

ul.navbar-main li a:hover::after {
  background-image: linear-gradient(90deg, #20d9a1 0%, #5f39ff 100%);
}


.angle-down {
  margin-left: 5px;
}

.about-block.col-lg-6.col-md-3.col-sm-6 {
  padding: 0;
}

.color.offcanvas-body {
  padding: 20px 50px;
}

div#block-21 {
  margin-bottom: 42px;
}

.mobile-view svg {
  color: rgb(3 25 157);
  font-size: 20px;
}

/* payement */
div.product {
  padding: 30px 30px;
  width: 100%;
  display: flex;
  background: #f5f9ff;
  align-items: center;
  max-width: 1080px;
  gap: 30px;
  margin: 20px auto 30px;
}

.payment-image {
  background-color: white;
  border: 10px solid #fff;
  box-shadow: rgb(0 0 0 / 19%) 0px 25px 20px -20px;
}

button.single_add {
  font-size: 0;
  background: #f5212e;
  padding: 16px 36px !important;
  margin-top: 12px;
  display: inline-block;
  border-radius: 3px;
  border: 0;
}

button.single_add:hover {
  font-size: 0;
  background: #392022;
}

button.single_add:before {
  content: "Proceed to pay";
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.product-input {
  border-radius: 3px !important;
  width: 100%;
  color: #666;
  padding: 0.75em;
  height: auto;
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 2px;
  box-shadow: none;
  margin-top: 10px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  background-color: #fff;
  box-shadow: none;
}

.product-label {
  width: 80%;
}

section.service-area {
  overflow: hidden !important;
}

.about-section-three {
  overflow: hidden !important;
}

.dropdown-menu-right.dropdown-menu.show {
  top: 10px !important;
}

.dropdown-li a {
  color: #212529 !important;
  font-weight: 600;
}

.dropdown-li {

  margin-bottom: 2px;
}

.single-service {
  padding: 36px 0 30px 0;
}

.page1-img-text img {
  width: 100%;
  height: 370px;
  object-fit: cover;
}

.page1-img-text h2 {
  font-size: 25px;
  font-weight: 600;
  padding-top: 30px;
  margin: 0;
  text-align: left;
  padding-bottom: 10px;
}

h2.heading {
  padding: 0;
}

.page1-img-text p {
  font-size: 18px;
  font-weight: 400;
  padding-top: 24px;
  text-align: justify;
}

.page1-left-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page1-img-text h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: left;
}

.page1-left-text ul li {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.page1-left-text ul li i {
  border: 1px solid;
  padding: 3px 2px;
  font-weight: 700;
  margin-right: 6px;
  color: gold;
}

.page1-img-text h5 {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-top: 25px;
}
.about-section-images img{
  border-radius: 10px;
  height: 560px;
  width: auto;
}
.about-main-section .about-second-image{
  position: absolute;
  right: 0;
  opacity: 0.3;

}
.common-content {
  margin: 20px 0 90px;
}
.about-main-section{
  position: relative;
  /* background-image: url(../src/Components/Assets/request-one_bg.webp); */
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 100px 0 140px;
}
.page1-left-text h6 {
  font-size: 20px;
  margin: 15px 0;
  font-weight: 600;
}

.page1-left-text {
  text-align: justify;
  padding-right: 15px;
}

.page1-left-text p {
  font-size: 18px;
  font-weight: 400;
}

.page1-right-pic img {
  width: 100%;
  height: 243px;
  object-fit: cover;
}

.page1-right-pic2 img {
  height: 330px;
}

.page1-right-pic3 img {
  height: 380px;
  width: 92%;
}

.service-page1-right {
  margin-left: 50px;
  position: sticky;
  top: 50px;
}

.service-menu {
  box-shadow: 0px 0px 15px #0000001c;
  padding: 28px;
  margin-bottom: 30px;
  position: relative;
}

.service-menu h6 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}

.service-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.service-menu ul a {
  color: black;
}

.service-menu ul a:hover {
  color: #00294b;
}

.service-menu ul li {
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}

.service-menu ul li i {
  float: right;
}

.form-fill input {
  width: 100%;
  height: 45px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid rgba(204, 204, 204, 0.58);
  display: block;
  margin-bottom: 18px;
}

.form-fill span svg {
  color: #34495e;
  position: absolute;
  font-size: 20px;
  right: 40px;
  margin-top: 12px;
}

.form-fill textarea {
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 0.58);
}

.form-fill button {
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  color: white;
  background: #d81320;
  border: none;
  border-radius: 5px;
  padding: 12px 33px;
  letter-spacing: 2px;
  margin-top: 15px;
}

.payment-bg {
  background-color: #fafafb;

}

.checkout-form {
  display: flex;
  ;
  padding: 50px 0 70px;
}

.checkout-review {
  width: 60%;
  padding: 0 100px;
}

.order-review {
  width: 40%;
 
}

.billing-fields input,
label,
select {
  width: 100%;
  
}
.billing-fields input:focus-visible,select:focus-visible {
  border-style: dotted !important;
  border-color: inherit !important;
  border-width: thin !important;
  outline: none !important;
}

.billing-fields input,
select {
  height: 45px;
  padding: 0 10px;
  border: 1px solid #E5E5E5 !important;

}

.billing-fields label .required {
  color: rgb(3 25 157);
  font-weight: 700;
  border: 0;
  text-decoration: none !important;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: none  !important;
}
.billing-fields label {
  font-weight: 500;
  padding-top: 10px;
}

.order-review div{
  border: 2px solid #E5E5E5;
  padding: 20px;
}
.order-review table {
  margin:  20px 0;
}
.order-review table th,td{
  width: 100%;
padding: 15px 0;

}
.order-review table tr{
  border-bottom: 1px solid #E5E5E5;
}
.button-place-order{
    background: #f5212e;
    width: 100%;
    padding: 10px 30px;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 600;
}

.privacy-link
{
  font-weight: 600;
  color: rgb(3 25 157);
}















/* second site */

.media{
  padding: 20px 20px;
  display: flex;
  gap: 40px;
 align-items: center;
  border: 1px solid #ccc;
  margin: 10px;
}
.media-body{
  position: relative;
}
.service-icon-img-wrap {
  position: absolute;
  width: 70px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: rgb(3 25 157);
  top: 30px;
  padding: 10px;
  right: -10px;
}
.service-icon-img-wrap img{

 filter: brightness(100)
}
.post-thumb{
  width: 400px;
  position: relative;
}
.post-title-head a{
  color: black;
}
.post-excerpt p{
  color: #7e7e7e
}
.media:hover:before {
  transition: all .3s;
  width: 100%;
}
.media:before {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  background: rgb(3 25 157);
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  transition: all .3s;
}
.media {
  position: relative;
}

.time-one_image.now-in-view {
  margin: 30px 0 0 0;
}
.time-one_image.now-in-view img{
border-radius: 20px;
}
.scrub-each{
font-size: 60px;
font-weight: 700;
color: black;
}
.title-metre{
   font-size: 30px;
    font-weight: 900;
    color: #090909;
}
.title-main{
    position: relative;
    overflow: hidden;
    padding: 30px 30px 5px;
    background-color: #f8f8f8;
    border-radius: 10px 10px;
    padding-top: 15px;
    width: 250px;
}
.title-main h6{
    color: #ec3900;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.5;
}

.feature-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 134px;
  margin-bottom: 40px;
}

.feature-box-inner {
  text-align: center;
}

.feature-box-img img {
  width: 250px;
  height: 280px;
  object-fit: cover;

  border-radius: 5px;
  border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: rgb(3 25 157);
    position: relative;
    margin-top: -134px;
}

.feature-box-number {
  color: rgb(3 25 157);
  opacity: calc(10 / 10);
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  padding: 7px 16px;
  border-radius: 50%;
    background: #fff;
  top: 35%;
  left: 45%;
}

.feature-box-title {
  font-size: 1.5rem;
  margin: 10px 0;
}

.feature-box-inner {
  padding: 0px 35px 39px 35px;
  background-color: #FEF8F8;
  border-radius: 0px 0px 0px 0px;
  position: relative;
}
.feature-box-content{
  margin-top: 35px;
}
.feature-box-title{
  color: black;
}

.chhose-content{
  position: absolute;
  z-index: 99;
  color: white !important;
  text-align: center;
  top: 10%;
  margin: 0 auto;
}
.chhose-content h2{
 
  color: white !important;
width: 50%;
margin: 0 auto;
}
.feature-chhose-main{

  display: flex;
  gap: 10px;
justify-content: center;
align-items: center;
padding:30px 300px;
position: relative;

overflow: hidden !important;
}
.cea-featured-icon i{

  background-color: white;
  color: #D70006;
  padding: 10px 15px;
  border-radius: 50%;
  font-size: 30px;
  
}
.cea-featured-icon i:hover{

  background-color: black;
  color: white;

  
}
h4.feature-content{
color: white;
margin: 30px 0 10px;
}

.main-feature {
  display: flex;
  border-bottom: 1px solid #00000012;
  padding: 40px 0;

}
.second-feature{
  display: flex;
  padding: 40px 0;
}
.feature-box-border{
  border-right: 1px solid #00000012;
  padding-right: 30px;
}
.feature-box{
  padding-left: 30px;
}
.icon-feature{
  color: rgb(3 25 157);
  font-size: 40px;

}
.title-feature{
  color: black;

  margin: 20px 0;
}
.section-wrapper{
  background-color: #fef8f8;
  padding: 40px;
  margin-right: 25px;
  margin-top: 70px;
}
.title-wrap span {
  color: rgb(3 25 157);
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}
.title-wrap{
  text-align: center;
}
.title-wrap h2{
  font-size: 50px;
  margin: 30px auto 20px;
  text-align: center;
  color: black;
}
.trust-score-wrapper p{
  text-align: center;
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 700;
}

.elementor-star-full{
color: rgb(3 25 157);
font-size: 25px;
}
.elementor-star{
font-size: 25px;
}
.elementor-star-rating{
  text-align: center;
}
.button-element{
text-align: center;
}

.hero-main {
  width: 100%;
  position: relative;
  background-image: url(../src/Components/Assets/download.jpg);
  content: '';
 height:680px;
  background-repeat: no-repeat;
  border-top: 1px solid #ccc;
  z-index: 9;
  overflow: hidden;
}

.hero-main .bg-gradient {
  background: linear-gradient(to bottom right, black, transparent);
  mix-blend-mode: multiply;
}

.hero-main .translate-middle-y {
  transform: translateY(-50%);
}


.hero-main .btn-circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: none;
}

.hero-main .bg-gradient-active {
  background: linear-gradient(to right, green, purple);
}

.hero-main .btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, teal, indigo);
  color: white;
  padding: 1rem 2rem;
  font-family: 'Heading Font', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  transition: background 0.4s ease-in-out;
}

.hero-main .btn-primary:hover {
  background: gray;
}
.sec-title-main{
  width: 38%;
  margin-top: 50px;
  margin-left: 100px;

}
.sec-title-main h2{
  font-size: 60px;
  font-weight: 700;
  color: black;
}

img.img-first {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.why-choose-us-two .overlay-line {
  background-image: url(../src/Components/Assets/hor-line-1.png);
  background-position: 199px 40px;
  background-repeat: no-repeat;
  background-size: 40% auto;
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 11px;
  left: 260px;
  position: absolute;
}

.disclaimer-main {
  text-align: justify;
  margin-bottom: 20px;
}
.more-info {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}


.about-title-main img{
  width: 70px !important;
  height: 60px !important;
}
.about-left{
  position: relative;
}
.about-bottom{
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 12px 12px;
  left: 20%;
  bottom: -63px;
}
.about-bottom img{
  width: 130px;
  height: 120px;
  border-radius: 12px 0px 0px 12px;
}

.about-title-main {
  overflow: hidden;
  padding: 16px 30px 5px 16px;
  gap: 15px;
  padding-top: 15px;
  display: flex;
}
.bg-image-about{
  background-image: url(../src/Components/Assets/cost-bg.webp);
  padding: 60px 0;
  margin: 20px 0 0 0 !important;
  position: relative;
  
}
.bg-image-about::before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.70;
  background-color: rgb(20, 20, 20);
}

.about-content-main {
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  bottom: 0px;
  position: absolute;
  left: -38.2%;
}
.about-service-main svg{
  width: 70px !important;
  height: 52px !important;
}
.about-service-main{
  display: flex;
  padding: 16px 33px 13px 16px;
  background-color: rgb(254, 190, 66);
  align-items: center;
}
.about-service-right{
  background-color: white;

  padding: 16px 33px 13px 16px;
}
.about-service-right svg{
 margin-right: 10px;
 color: rgb(3 25 157);
 font-size: 20px !important;
}
.about-service-right p{
margin: 4px;
font-weight: 700;
}

.about-bottom-left img{
  width: 70px !important;
  height: 60px !important;
  margin: 0 auto;
}
.about-bottom-left{
  
  overflow: hidden;
  padding: 30px 30px 5px 16px;
  gap: 10px;
  padding-top: 15px;
 display: block;
}

.about-section-middle{
  border-radius: 10px;
  height: 550px;
  width: auto;
  left: 20%;

  position: relative;
}
.about-section-right{
  position: absolute;
right: 0;
height: 490px;
width: 300px;
border-radius: 10px;
object-fit: cover;
top: 5%;
}
.about-section-left {
  position: absolute;
  left: 0;
  height: 400px;
  width: 250px;
  border-radius: 10px;
  object-fit: cover;
  top: 10%;
}
.section-bottom-left{
  overflow: hidden;
  padding: 16px 30px;
  border-radius: 20px;
  position: absolute;
  background: white;
  text-align: center;
  bottom: -15%;
  left: 35%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10)
}
.section-bottom-left img{
  width: 70px !important;
  height: 60px !important;
  margin: 10px auto 20px;
  border-radius: 10px;
  background-color: #ebebeb;
  padding: 13px;
}
.inner-about-main{
  margin-left: 40px;
}

.page-breadcrumb-two li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 0px;
  color: #fff;
  font-size: 16px;
  /* text-align: justify; */
  /* font-weight: 400; */
  /* text-transform: capitalize; */
}
.page-breadcrumb-two li a {
  color: #ffbf43;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.text-center-padding{
  text-align: center;
  padding-top: 100px;
}
.nav-icon {
  margin-right: 14px;
  font-size: 22px;
  color: rgb(3 25 157);
}