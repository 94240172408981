/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --white-color: hsl(0, 0%, 100%);
  --black-color: hsl(0, 0%, 0%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Montserrat Alternates", sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-medium: 500;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}


ul {
  list-style: none;
}

a {
  text-decoration: none;
}


/*=============== HEADER & NAV ===============*/
.header {
    position: relative;
  background-color: white;
  z-index: var(--z-fixed);
}
.nav {
    position: relative;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    font-family: var(--body-font);
}


.nav__logo {
  color: var(--white-color);
  font-weight: var(--font-medium);
}
.dropdown-main{
  position: absolute;
    background: white !important;
    width: 300px !important;
    padding: 15px 25px  !important;
}
.dropdown-main li{
    margin-bottom: 8px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}.dropdown-main li a{
    color: rgb(3 25 157);
    font-weight: 500;

}
.nav__close, 
.nav__toggle {
  display: flex;
  font-size: 1.5rem;
  cursor: pointer;
}
.nav-item{
    position: relative;
  }
/* Navigation for mobile devices */
@media screen and (max-width: 1150px) {
  .nav__menu {
    position: fixed;
    left: -100%;
    top: 0;
    background-color: var(--black-color);
    width: 100%;
    height: 100%;
    padding: 6rem 3.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: left .4s;
  }

  .nav__item {
    transform: translateX(-150px);
    visibility: hidden;
    transition: transform .4s ease-out, visibility .4s;
  }
  

  .nav__item:nth-child(1) {
    transition-delay: .1s;
  }
  .nav__item:nth-child(2) {
    transition-delay: .2s;
  }
  .nav__item:nth-child(3) {
    transition-delay: .3s;
  }
  .nav__item:nth-child(4) {
    transition-delay: .4s;
  }
  .nav__item:nth-child(5) {
    transition-delay: .5s;
  }
}

.nav__list, 
.nav__social {
  display: flex;
}

.nav__list {
  flex-direction: column;
  row-gap: 3rem;
}

.nav__link {
  position: relative;
  color: var(--white-color);
  font-size: var(--h1-font-size);
  font-weight: var(--font-medium);
  display: inline-flex;
  align-items: center;
  transition: opacity .4s;
}

.nav__link i {
  font-size: 2rem;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s, visibility .4s;
}

.nav__link span {
    position: relative;
    transition: margin .4s;
    color: rgb(3 25 157) ;
}
.nav__link span:hover {
  
    color: black ;
}

.nav__link span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 0;
  height: 2px;
  background-color: var(--white-color);
  transition: width .4s ease-out;
}

/* Animation link on hover */
.nav__link:hover span {
  margin-left: 2.5rem;
}

.nav__link:hover i {
  opacity: 1;
  visibility: visible;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
.nav__link:hover span::after {
  width: 100%;
}

/* Sibling fade animation */
.nav__list:has(.nav__link:hover) .nav__link:not(:hover) {
  opacity: .4;
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.nav__social {
  column-gap: 1rem;
}

.nav__social-link {
  color: var(--white-color);
  font-size: 1.5rem;
  transition: transform .4s;
}

.nav__social-link:hover {
  transform: translateY(-.25rem);
}

/* Show menu */
.show-menu {
  left: 0;
}

/* Animation link when displaying menu */
.show-menu .nav__item {
  visibility: visible;
  transform: translateX(0);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (min-width: 1150px) {


  .nav {
    height: calc(var(--header-height) + 2rem);
  }

  .nav__toggle, 
  .nav__close {
    display: none;
  }

  .nav__link {
    font-size: var(--normal-font-size);
  }

  .nav__link i {
    font-size: 1.5rem;
    color: black;
}

  .nav__list {
    flex-direction: row;
    column-gap: 3.5rem;
  }

  .nav__menu {
    display: flex;
    align-items: center;
    column-gap: 3.5rem;
  }
}
.text-font a p{
  font-size: 16px;
  color: white;
}

.text-font a p:hover{
  
  color: #fb7405;
}
.text-font a p svg{ 
 margin-right: 10px;
}
